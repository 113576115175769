import { render, staticRenderFns } from "./buyRoom.vue?vue&type=template&id=1476cd99&scoped=true"
import script from "./buyRoom.vue?vue&type=script&setup=true&lang=js"
export * from "./buyRoom.vue?vue&type=script&setup=true&lang=js"
import style0 from "./buyRoom.vue?vue&type=style&index=0&id=1476cd99&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1476cd99",
  null
  
)

export default component.exports