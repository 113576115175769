<template>
    <!--空间购买  -->
    <div>
        <div class="dialog-container">
            <el-dialog  :show-close="false" :close-on-click-modal="false" :visible.sync="isBuySpaceWin" width="1200px" custom-class="login-dialog" class="custom-dialog" top="3vh">
                <BuyRoom />
            </el-dialog>
        </div>
        <!--支付成功  -->
        <div class="dialog-container">
            <el-dialog :visible.sync="isPaySuccessWin" width="1200px" custom-class="login-dialog" class="custom-dialog" top="10vh">
                <paySuccessWin />
            </el-dialog>
        </div>
    </div>

</template>

<script>
    import BuyRoom from "./buyRoom.vue";
    import paySuccessWin from "@/components/common/Pay/vip/paySuccessWin.vue";
    export default {
        name: "RoomBuySpace",
        components: {
            BuyRoom,
            paySuccessWin,
        },
        computed: {
            user() {
                return this.$store.state.user
            },
            is_login() {
                return this.$store.state.is_login
            }
        },
        data() {
            return {
                isBuySpaceWin: true,
                isPaySuccessWin:false,
            }
        },
        created() {
            var that = this;
            this.$EventBus.$EventBus.$off("payMsg")
            this.$EventBus.$on("payMsg", (data) => {
                this.$nextTick(() => {
                    that.isBuySpaceWin = false;
                    that.isPaySuccessWin = true;
                })
            })
        },
    }
</script>
<style >
.el-dialog__header{
    padding: 0px;
}
.el-dialog__body {
    padding: 0px;
}
.dialog-container{
    border-radius: 13px 13px 0px 0px;
}
</style>